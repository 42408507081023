import $ from "jquery";

import imagesLoaded from "imagesloaded";
import Isotope from "isotope-layout";

$(document).ready(function() {

    let iso = undefined;
    //isotope
    if ($('#grid').length > 0) {
        iso = new Isotope( '#grid', {
            //layoutMode: 'fitColumns',
            itemSelector: '.grid-item'
        });
        $(window).one("load", function() {
            iso.layout();
        });
    }

    const isProjektOv = (iso != undefined);
    const isWerkliste = location.href.includes("werkliste");

    const $filter = $('.filter');

    if(isProjektOv) {
        function switchFilter(filterValue) {
            //param filterValue = z.B. ".innenarchitektur"

            //Filter isotope
            //  use filterFn if matches value
            //  filterValue = filterFns[ filterValue ] || filterValue;
            iso.arrange({ filter: filterValue });

            //Highlight button
            $filter.find('.is-checked').removeClass('is-checked');
            $filter.find("[data-filter='"+filterValue+"']").addClass("is-checked");
        }

        // bind filter button click
        $filter.on( 'click', '.label', function() {
            const $filterButton = $(this);
            const filterValue = $( this ).attr('data-filter'); //z.B. ".innenarchitektur"
            switchFilter(filterValue);
            //$filterButton.addClass('is-checked');
        });

        if(window.location.hash && window.location.hash != "#all") {
            const hashFilter = "."+window.location.hash.substring(1); //z.B. ".innenarchitektur"
            switchFilter(hashFilter);
        } else {
            //Highlight "Alle"
            $filter.find(".label[data-filter='*']").addClass("is-checked");
        }
    } else if(isWerkliste) {
        $filter.find(".label[href*='werkliste']").addClass("is-checked");
    }
});

