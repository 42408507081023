import $ from "jquery";

import 'slick-carousel';
window.jQuery = window.$ = $;

$(document).ready(function() {

    //Home
    const $homeslide = $('#homeslide');
    const numslides = $homeslide.find(".slider-item").length;
    const startSlide = Math.floor(Math.random() * numslides);
    $homeslide.slick({
        infinite: true,
        variableWidth: true,
        autoplay: true,
        arrows: false,
        fade: true,
        autoplaySpeed: 5000,
        speed: 3000,
        pauseOnHover: false,
        initialSlide: startSlide
    });

    //Default / Projekte
    const $slider = $('#slider'); 
    $slider.slick({
        infinite: true,
        variableWidth: true,
        slidesToScroll: 1,
        nextArrow: '<div class="next-arrow"><span class="svg-icon"><svg class="icon"><use xlink:href="#icon-arrow"></use></svg></span></div>',
        prevArrow: '<div class="prev-arrow"><span class="svg-icon"><svg class="icon"><use xlink:href="#icon-arrow"></use></svg></span></div>'
    });
    $slider.find(".prev-arrow").hide();
    $slider.on("beforeChange", function(event, slick, currentSlide, nextSlide) {
        slick.$slider.find(".prev-arrow").fadeIn(200);
    });

    //Team
    let teamSliderInitialized = false;
    let teamSlider = undefined;
    $(".team-btn").on("click", function() {
        const teamIdx = $(this).data("teamidx");

        const $teamSlider = $('#team-slider');
        if(teamSliderInitialized) {
            //Bug bei "display:none" des Modal beheben. (Slick kann Grösse bei unsichtbaren Elementen nicht bestimmen.)
            $teamSlider.slick("unslick");
        }
        $teamSlider.slick({
            infinite: true,
            initialSlide: teamIdx-1,
            nextArrow: '<div class="next-arrow"><span class="svg-icon"><svg class="icon"><use xlink:href="#icon-arrow"></use></svg></span></div>',
            prevArrow: '<div class="prev-arrow"><span class="svg-icon"><svg class="icon"><use xlink:href="#icon-arrow"></use></svg></span></div>'
        });
        teamSliderInitialized = true;

        teamSlider = $teamSlider[0].slick;
        //teamSlider.slickGoTo(teamIdx-1, true); //true = dont animate
    });


    //Mobile Next/Prev Buttons
    const $teamModal = $("#teamModal");
    $teamModal.find(".mobilenext").on("click", function() {
        teamSlider.slickNext();
    });
    $teamModal.find(".mobileprev").on("click", function() {
        teamSlider.slickPrev();
    });

});


