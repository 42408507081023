import $ from "jquery";
import "what-input";
import libs from "./lib/dependencies.js";

window.$ = $;
window.jQuery = $;
window.libs = libs;

//import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import 'foundation-sites'
import "./lib/foundation-explicit-pieces.js";

//Pixmill Scripts
import { ready } from "./px/utils.js";

import "./px/hamburger.js";
import "./px/opaque.js";
import "./px/scrolldown.js";
import "./px/slick.js";
import "./px/isotope.js";
import "./px/lazyload.js";
//import "./px/lightgallery.js";
import "./px/search.js";

import { addFunctionalityToProgressPath } from "./px/scrollProgress.js";

ready(function () {
  addFunctionalityToProgressPath("progress-wrap");
});

$(document).ready(function(){
  $('.search-top').click(function(){
    $('#modal-search').focus();
  });


  //Home Overlay
  const $startOverlay = $('.start-overlay');
  if($startOverlay.length > 0) {
    const startOverlaySeen = sessionStorage.getItem("startOverlaySeen");
    if(startOverlaySeen != "1") {
      $startOverlay.show();
      sessionStorage.setItem("startOverlaySeen", "1");
    }

    function closeStartOverlay() {
      $startOverlay.addClass('closed');
    }
    $startOverlay.on('click', function() {
      closeStartOverlay();
      //$('#animated-logo .fadein').addClass('active');
      //$('#animated-logo .redtogray').addClass('active');
    });
    $startOverlay.find('iframe').on('click', function() {
      closeStartOverlay();
    });
    setTimeout(closeStartOverlay, 5000);
  }

});


$(document).foundation();
