import $ from 'jquery'
//To Top Button
$(document).ready(function() {

    $(".arrowdown").on("click", function(e) {
        $('html, body').animate({
            scrollTop: $(this).parent().next().offset().top  - 80
        }, 800);
    });

});